import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'faq-search-component',
})
export default class FaqSearchComponent extends Vue {


}
