import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {container} from 'tsyringe';
import {store} from "@/store";
import {FaqSandbox} from '@/pages/faq/sandbox/faq.sandbox';
import FaqSearchComponent from '@/pages/faq/components/faq-search/faq-search.component.vue';
import FaqAccordionComponent from '@/pages/faq/components/faq-accordion/faq-accordion.component.vue';
import FaqAccordionItemComponent from '@/pages/faq/components/faq-accordion-item/faq-accordion-item.component.vue';

@Options({
    name: 'faq-component',
    components: {
        FaqSearchComponent,
        FaqAccordionComponent,
        FaqAccordionItemComponent,
    }
})
export default class FaqComponent extends Vue {
    private sandbox: FaqSandbox = container.resolve(FaqSandbox);

    get questions(): any {
        return store.getters._getFaqs
    }

    public created(): void {
        this.sandbox.getFaqs()
    }

}
