import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "faq mb-100 mt-40" }
const _hoisted_2 = { class: "container-fluid v-container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faq_accordion_component = _resolveComponent("faq-accordion-component")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_v_card, { class: "mt-20" }, {
            default: _withCtx(() => [
              _createVNode(_component_faq_accordion_component, { questions: _ctx.questions }, null, 8, ["questions"])
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}