import 'reflect-metadata';
import {injectable} from 'tsyringe';
import {store} from '@/store';


@injectable()
export class FaqSandbox {
    private store = store;

    constructor() {
    }

    getFaqs(): void {
        this.store.dispatch('getFaqs');
    }

}