import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'faq-accordion-item-component',
    props: {
        question: {
            type: Object,
        },
    }
})
export default class FaqAccordionItemComponent extends Vue {
    public active = false;
    public $refs!: {
        content: HTMLElement,
        wrapper: HTMLElement,
    };

    public itemClick() {
        let contentHeight = this.$refs.content.clientHeight,
            wrapperHeight = this.$refs.wrapper.clientHeight;
        if (contentHeight != wrapperHeight) {
            this.$refs.wrapper.style.maxHeight = `${contentHeight + 25}px`
        }
        if (contentHeight < wrapperHeight) {
            this.$refs.wrapper.style.maxHeight = `0`
        }
        this.active = !this.active

    }
}
