import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import FaqAccordionItemComponent from "@/pages/faq/components/faq-accordion-item/faq-accordion-item.component.vue";

@Options({
    name: 'faq-accordion-component',
    components: {
        FaqAccordionItemComponent
    },
    props: {
        questions: {
            type: Array,
            require: true,
        }
    }
})
export default class FaqAccordionComponent extends Vue {
    public faqIndex: number | null = null;
    public questions = [];
    public toggle(ind: number) {
        if(this.faqIndex === ind) {
            this.faqIndex = null
        } else {
            this.faqIndex = ind
        }
    }

}
