import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "faq-accordion" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faq_accordion_item_component = _resolveComponent("faq-accordion-item-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions, (qst, index) => {
      return (_openBlock(), _createBlock(_component_faq_accordion_item_component, {
        onClick: ($event: any) => (_ctx.toggle(index))
      }, {
        question: _withCtx(() => [
          _createTextVNode(_toDisplayString(qst.name), 1)
        ]),
        content: _withCtx(() => [
          _createTextVNode(_toDisplayString(qst.content), 1)
        ]),
        _: 2
      }, 1032, ["onClick"]))
    }), 256))
  ]))
}