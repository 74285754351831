import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fag-accordion-item__question" }
const _hoisted_2 = {
  class: "fag-accordion-item__wrapper",
  ref: "wrapper"
}
const _hoisted_3 = {
  class: "fag-accordion-item__content",
  ref: "content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["fag-accordion-item", {'fag-accordion-item__active':_ctx.active}]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.itemClick && _ctx.itemClick(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "question")
    ]),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "content")
          ], 512)
        ], 512)
      ]),
      _: 3
    })
  ], 2))
}